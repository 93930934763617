import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { IntercomAPI } from 'react-intercom'
import classNames from 'classnames/bind'
import { Dropdown } from 'components'
import { LegoHead as UserIcon } from 'components/uiKit/icons'
import { useCurrent, useAuth } from 'contexts'
import { useNavigationPermission } from 'layout/NavigationPermissionProvider/NavigationPermissionProvider'
import { SettingsNavigationRoutes } from 'layout/routesConfig'
import { formatDisplayName } from 'utils/formatting'
import styles from './UserMenu.module.scss'

const cx = classNames.bind(styles)

const UserMenu = () => {
  const { t } = useTranslation()
  const { isImpersonating, logout } = useAuth()
  const { currentUser: user, doesCurrentUserHavePermission } = useCurrent()
  const defaultSettingsRoute = SettingsNavigationRoutes.find(
    ({ permissions }) =>
      permissions.every(permission => doesCurrentUserHavePermission(permission))
  )?.path
  const { hasSomePermission } = useNavigationPermission({
    permissions: SettingsNavigationRoutes.map(route => route.permissions),
  })
  const dropdownRef = useRef()
  const triggerRef = useRef()
  const [isOpen, setOpen] = useState(false)

  return (
    <div className={styles.container}>
      <button
        type="button"
        aria-label="toggle-user-menu"
        aria-haspopup="true"
        aria-expanded={isOpen}
        ref={triggerRef}
        className={styles.user}
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        <div
          className={cx('avatar', {
            'avatar--default': !user.avatar,
          })}
        >
          {user.avatar ? (
            <img
              src={user.avatar}
              alt={`${formatDisplayName(user)} ${t('avatar')}`}
            />
          ) : (
            <UserIcon />
          )}
        </div>
      </button>
      <Dropdown
        ref={dropdownRef}
        origin="left"
        isOpen={isOpen}
        onChange={setOpen}
        triggerRef={triggerRef}
        className={styles.dropdown}
      >
        <div className={styles.links}>
          <Link
            to="/user_settings"
            className={styles.link}
            onClick={() => setOpen(false)}
          >
            <span>{t('My profile')}</span>
          </Link>
          {hasSomePermission && (
            <Link
              to={defaultSettingsRoute}
              className={styles.link}
              onClick={() => setOpen(false)}
            >
              <span>{t('Admin settings')}</span>
            </Link>
          )}
          <a
            href="https://intercom.help/blocks"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            onClick={() => setOpen(false)}
          >
            <span>{t('Support articles')}</span>
          </a>
          <button
            type="button"
            className={styles.link}
            onClick={() => {
              setOpen(false)
              IntercomAPI('show')
            }}
          >
            <span>{t('Ask for help')}</span>
          </button>
          {!isImpersonating && (
            <button type="button" className={styles.link} onClick={logout}>
              <span>{t('Logout')}</span>
            </button>
          )}
        </div>
      </Dropdown>
    </div>
  )
}

export default UserMenu
