import {
  TextField,
  NumberField,
  SelectField,
} from '@politechdev/blocks-design-system'
import humanizeString from 'humanize-string'
import { CustomFieldConfig, CustomFieldValue } from './CustomFields.types'

const CustomFields = ({
  fields,
  onChange,
  currentData,
  disabled,
}: {
  fields: CustomFieldConfig[]
  onChange: (attribute: string, value: CustomFieldValue) => unknown
  currentData: Record<string, CustomFieldValue>
  disabled?: boolean
}) => (
  <>
    {fields.map(field => {
      if (field.options.length) {
        return (
          <SelectField
            label={field.label || humanizeString(field.name)}
            value={currentData[field.name]}
            onSelect={val => onChange(field.name, val)}
            required={field.required}
            disabled={disabled}
            options={field.options.map(option => ({
              label: humanizeString(option),
              value: option,
            }))}
          />
        )
      }

      return field.type === 'integer' ? (
        <NumberField
          label={field.label || humanizeString(field.name)}
          value={currentData[field.name] as number}
          onChange={val => onChange(field.name, val)}
          required={field.required}
          disabled={disabled}
        />
      ) : (
        <TextField
          label={field.label || humanizeString(field.name)}
          value={currentData[field.name] as string}
          onChange={val => onChange(field.name, val)}
          required={field.required}
          disabled={disabled}
          icon={undefined}
        />
      )
    })}
  </>
)

export default CustomFields
