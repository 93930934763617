export const shiftReqParams = {
  fields: [
    {
      petition_packet: [
        'id',
        'filename',
        'file_url',
        'county',
        {
          assignee: ['id'],
        },
        {
          pages: [
            'id',
            'number',
            'notes',
            { visual_review_responses: ['id', 'response'] },
            {
              signatures: [
                'id',
                'line_number',
                'first_name',
                'middle_name',
                'last_name',
                'address_one',
                'address_two',
                'city',
                'state',
                'zipcode',
                'county',
                'ostraka_external_id',
                'visual_review_approved',
                { visual_review_responses: ['id', 'response', 'active'] },
                { reviewer: ['full_name', 'name', 'email'] },
              ],
            },
          ],
        },
      ],
    },
    {
      turf: [
        'name',
        'petition_requirements',
        {
          visual_review_responses: [
            'id',
            'description',
            'response',
            'active',
            'grouping_metadata',
            'reviewable_type',
          ],
        },
      ],
    },
    { canvasser: ['full_name'] },
    'shift_start',
    'status',
  ],
}

export const signatureReqParams = {
  fields: [
    'id',
    'line_number',
    'first_name',
    'middle_name',
    'last_name',
    'address_one',
    'address_two',
    'city',
    'state',
    'zipcode',
    'county',
    'ostraka_external_id',
    { visual_review_responses: ['id', 'response'] },
    { reviewer: ['full_name', 'name', 'email'] },
    { packet: ['filename', { shift: ['status'] }] },
    { page: ['number'] },
  ],
}

export const dupePinSignatureReqParams = id => ({
  filters: {
    rules: [
      {
        column: 'ostraka_external_id',
        operator: 'is',
        param: id,
      },
    ],
  },
  ...signatureReqParams,
})

export const potentialDupeSignatureReqParams = (formData, signatureId) => {
  const keys = [
    'first_name',
    'middle_name',
    'last_name',
    'address_one',
    'address_two',
    'city',
    'state',
    'zipcode',
  ]
  return {
    filters: {
      rules: keys
        .map(key =>
          formData[key]
            ? { column: key, operator: 'is', param: formData[key] }
            : undefined
        )
        .concat([
          {
            column: 'visual_review_approved',
            operator: 'is',
            param: true,
          },
          {
            column: 'id',
            operator: 'is',
            param: signatureId,
            invert: true,
          },
        ]),
    },
    ...signatureReqParams,
  }
}

export const serializeFields = [
  'first_name',
  'middle_name',
  'last_name',
  'address_one',
  'address_two',
  'city',
  'state',
  'zipcode',
  'county',
  'ostraka_external_id',
]

export const FORM_STATE = {
  READ_ONLY: 'read_only_view',
  VIEW_REVIEW: 'editable_view',
  REVIEW: 'review',
}
